/* Count Step Form */
@mixin style-w2c {
  /* Header Part For Inner Pages */
  .text-primary {
    color: #00adcc !important;
  }
  .header {
    z-index: 9999;
  }
  .header-top {
    color: #fff;
  }
  img.mainlogo {
    width: 167px;
  }
  img.call-icon {
    width: 26px;
  }
  .header-top span.number {
    color: #fff;
    font-weight: 700;
  }
  .header-top span.dayntime {
    color: #fff;
    font-weight: 400;
  }
  img.calender-icon {
    width: 22px;
  }
  img.warringicon {
    width: 17px;
    margin-right: 8px;
    margin-top: -4px;
  }
  .alert.customnotice {
    background: #00adcc;
    border: none;
    color: #fff;
  }
  .navwithbg {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
    transition: ease all 0.2s;
  }
  section.header.position-fixed.solidheader { 
    right: 0;
  }
  section.header.solidheader .navwithbg {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
  }
  .nav-link {
    color: #000;
  }

  span.specialbtn {
    background: #00c5e8 !important;
  }
  .customnotice.text-center.rounded-0.rounded-bottom {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  button.btn-close.filter {
    filter: invert(1);
  }
  .solidheader .borderonscroll {
    border-bottom: 1px solid #ddd;
    background: #fff;
  }
  .solidheader .header-top span.number {
    color: #00adcc;
  }
  .solidheader .header-top span.dayntime {
    color: #263238;
  }
  .solidheader img.calender-icon {
    filter: brightness(0.5);
  }
  .solidheader img.call-icon {
    filter: brightness(0.5);
  }
  .solidheader .shadowonscroll {
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
    z-index: 99999999;
  }
  .notificationss {
    z-index: -1;
    position: relative;
  }

  /* Hover Manu #209faf */
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link.active {
    color: #00adcc !important;
  }
  .navbar-nav .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-nav .dropdown-item:hover {
    background-color: #00adcc;
    color: #fff !important;
  }

  /* ******End Header Part******* */

  /* *********Footer Part CSS Start************* */
  section.footer {
    background: #54575c;
    padding-top: 50px;
    margin-top: 50px;
    z-index: 999;
  }
  section.footer.quotes-steps-one,
  section.footer.quotes-steps-two {
    padding-bottom: 100px;
  }
  ul.list-unstyled.footerlink li {
    margin-bottom: 0.75rem;
  }
  .footer-top {
    border-bottom: 1px solid #00adcc;
  }
  .text-small {
    font-size: 13px;
  }
  .footer-bottom {
    padding-bottom: 50px;
  }
  /* ******End Footer Part******* */

  /* Pages CSS */
  .page-header-title {
    position: relative;
  }
  .page-header-title-caption {
    position: absolute;
    bottom: 135px;
    width: 100%;
    text-align: center;
    p {
      color: #fff;
    }
  }
  .page-header-title-caption h1 {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
  }
  .page-header-title-caption p {
    color: #fff;
  }
  .page-title-overlay {
    background: linear-gradient(180deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 30%));
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .page-title-image {
    min-height: 480px;
    object-fit: cover;
  }

  /* Page Title End */

  .page-content-info {
    margin-bottom: 40px;
  }
  .page-content-info h3 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 15px;
  }
  .page-content-info p,
  .page-content-info ol {
    color: #54575c;
    line-height: 1.7;
  }
  p.desclaimer small {
    color: #54575c;
    line-height: 21px;
    font-size: 10px;
    line-height: 1.7;
  }

  /* .page-content-info h2.icon{
  position: relative; 
}
.page-content-info h2.icon::before { 
  content: url(../assets/icons/w2c/ari-before.png);
  position: absolute;
  top: -15px;
  left: -35px;
  z-index: -1;
} */

  /* Aside/Sidebar Form  */
  .page-content aside {
    box-shadow: 0px 0px 10px rgb(0 173 204 / 25%);
    border-radius: 10px;
  }

  .quote-sidebar .quotehome .nav-link.active {
    color: #00adcc !important;
    font-weight: 700;
  }
  button.full-width {
    width: 100%;
  }

  .quote-sidebar .nav-tabs .nav-link {
    border: 1px solid #00adcc !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    background: #e8e8e8;
    /* padding-right: 28px;
  padding-left: 28px; */
    width: 48%;
    font-weight: 600;
  }
  .quote-sidebar .nav-tabs .nav-link.active {
    background: #fff;
    border-bottom: 0 !important;
    color: #00adcc !important;
  }
  .quote-sidebar .nav-tabs .nav-link:hover {
    background: #fff;
  }

  .quote-sidebar .nav-tabs {
    border-bottom: 1px solid #00adcc;
    justify-content: center;
  }
  .quote-info-text {
    background-color: #edf7f9;
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 10px;
  }

  .quote-sidebar .form-control,
  .quote-sidebar .form-select {
    height: 48px;
  }
  .quote-sidebar label {
    color: #54575c;
  }
  .quote-sidebar .form-select {
    background-image: url(../../icons/w2c/select_down.png) !important;
    background-size: 20px !important;
  }

  .select2-container .select2-selection--multiple {
    min-height: 48px;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: #e1f0f3 !important;
    border: 1px solid #0dcaf0 !important;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    margin-left: 5px;
    float: right;
    border: 1px solid #0dcaf0;
    border-radius: 35px;
    height: 20px;
    width: 20px;
    line-height: 18px;
    text-align: center;
    padding: 0;
    margin-top: 2px;
    color: #0dcaf0;
  }
  .sidebar-tooltipc {
    filter: grayscale(119) brightness(2.5);
  }

  .page-content-info ul {
    list-style: none;
    color: #54575c;
  }
  .page-content-info ul > li {
    position: relative;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .page-content-info ul li::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f111';
    color: #00adcc;
    position: absolute;
    right: 101%;
    top: 7px;
    height: unset;
    width: unset;
    background: transparent;
    font-size: 10px;
  }
  .page-content-info ul.check-circle li::before {
    content: '';
    position: absolute;
    right: 101%;
    top: 4px;
    height: 20px;
    width: 20px;
    background: url(../../icons/w2c/check_circle.png);
    background-size: 100%;
  }

  .page-content-info ul.link.list-group li::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f14a';
    top: 2px;
    font-size: 16px;
    color: #676767;
  }
  .page-header-title-caption p {
    font-weight: 700;
    font-size: 18px;
    padding: 0px 10px;
    max-width: 750px;
    margin: 0px auto;
  }
  .contact-us p.quotereq {
    top: 0px !important;
  }
  ul.link.list-group li {
    margin-left: 15px;
  }
  .page-content-info ul.link li::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f111';
    position: absolute;
    right: 101%;
    top: 7px;
    height: unset;
    width: unset;
    background: transparent;
    font-size: 10px;
    color: #00adcc;
  }
  .pds-button {
    height: 100%;
  }
  .pds-button button.btn-pds {
    width: 100%;
    height: 47%;
    background: #edf7f9;
    border: 0;
    color: #00adcc;
    text-align: center;
  }
  .pds-button button.btn-pds span {
    margin-bottom: 10px;
  }
  .pds-button button.btn-pds:hover {
    background: #00adcc;
    color: #fff;
  }
  .pds-button button.btn-pds:hover img,
  button.btn-pds-related:hover img {
    filter: grayscale(119) brightness(2.5);
  }
  button.btn-pds-related {
    background: #edf7f9;
  }
  button.btn-pds-related:hover {
    background: #00adcc;
  }

  /* FAQs Accordion CSS */
  .faqs-accorion .accordion-header {
    position: relative;
  }
  .faqs-accorion .accordion-title::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\2b';
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 22px;
    color: #00adcc;
  }
  .faqs-accorion .accordion-title:not(.collapsed)::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f068';
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 22px;
    color: #00adcc;
  }
  .faqs-accorion .accordion-title {
    padding: 15px;
    margin-bottom: 0;
    font-size: 20px;
    padding-right: 40px;
  }

  .faqs-accorion .accordion-title:not(.collapsed) {
    color: #00adcc;
    /* background-color: #F6F6F6; */
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
  .faqs-accorion .accordion-body {
    background-color: #f6f6f6;
  }

  /* End FAQs */

  /******** Claim Login Form CSS **************/
  .auth-form {
    box-shadow: 0 0 10px #a0d4dd;
    border-radius: 12px;
  }
  .auth-form p,
  .auth-form label {
    color: #54575c;
  }
  .auth-form .nav-tabs .nav-link {
    width: 50%;
    border-radius: 10px 10px 0 0;
    background-color: #f8f8f8;
    border-color: #00adcc;
  }
  .auth-form .nav-tabs .nav-link:hover,
  .auth-form .nav-tabs .nav-link:focus {
    border-color: #00adcc !important;
  }
  .auth-form .nav-tabs {
    background-color: #f8f8f8;
  }
  .auth-form .nav-tabs .nav-register,
  .auth-form .nav-tabs .nav-login {
    position: relative;
    padding: 15px;
    font-weight: 700;
  }
  .auth-form .nav-tabs .nav-login:not(.active) {
    border-right: unset;
    border-radius: 10px 0 0 0;
  }
  .auth-form .nav-tabs .nav-register:not(.active) {
    border-left: unset;
    border-radius: 0 10px 0 0;
  }

  .auth-form .nav-tabs .nav-login:not(.active)::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 1px;
    background: #00adcc;
    top: -1px;
    right: -15px;
  }
  .auth-form .nav-tabs .nav-register:not(.active)::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 1px;
    background: #00adcc;
    top: -1px;
    left: -15px;
  }
  .auth-form .nav-tabs .nav-link.active,
  .auth-form .nav-tabs .nav-item.show .nav-link {
    color: #00adcc !important;
    background-color: #fff;
    border-bottom: unset;
  }
  .auth-form input:focus {
    border: 1px solid #ced4da;
    outline: unset;
    box-shadow: unset;
  }

  /**************************************
  Helper Class
*****************************************/
  .inactive {
    opacity: 0.2;
  }
  .bg-gray {
    background-color: #b1b8bd;
  }
  .text-gray {
    color: #737882;
  }
  .bg-light-info {
    background-color: #edf7f9;
  }
  .fw-semibold {
    font-weight: 600;
  }
  .font-14 {
    font-size: 14px;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-18 {
    font-size: 18px;
  }
  .font-20 {
    font-size: 20px;
  }
  .rounded-10 {
    border-radius: 10px !important;
  }
  .rounded-20 {
    border-radius: 20px !important;
  }
  .rounded-top-10 {
    border-radius: 10px 10px 0 0 !important;
  }
  .rounded-top-20 {
    border-radius: 20px 20px 0 0 !important;
  }
  .bg-multi-header {
    background-color: #f5f5f5;
    position: relative;
    z-index: 99;
  }
  .p-highlight .p-tabview-nav-link:has(.bg-multi-header) {
    border-radius: 16px 16px 0px 0px !important;
  }

  .space-20 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /********************  Media/Responsive CSS Code 
***********************************************************/
  @media screen and (min-width: 992px) {
    .quotecontain-sidebar {
      margin-top: -156px;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
      background: #fff;
      min-width: 250px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .dropdown-item {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #00adcc;
    }
  }

  @media screen and (max-width: 992px) {
    .logofooter img.img-fluid {
      max-height: 100px !important;
    }
  }


  @media screen and (max-width: 1020px) and (min-width: 768px) {
    .social a img.me-md-4 {
      margin-right: 1rem !important;
    }
  }

  @media screen and (max-width: 1200px) {
    /*nav*/
    .navwithbg {
      border-radius: 0px;
    }
    .customnotice.text-center.rounded-0.rounded-bottom {
      border-bottom-right-radius: 0rem !important;
      border-bottom-left-radius: 0rem !important;
    }
    .text-xs-center {
      text-align: center;
    }
  }

  @media screen and (max-width: 991px) {
    /* Page Title */
    .page-header-title-caption {
    }
    /*  Mobile Dropdown menu alwasy open*/
    .navbar-nav .dropdown-menu {
      display: block !important;
      border: none !important;
    }
  }

  @media screen and (max-width: 768px) {
    section.footer.quotes-steps-one,
    section.footer.quotes-steps-two {
      padding-bottom: 160px;
    }

    #offcanvasNavbar {
      width: auto;
      max-width: 400px;
    }

    span.d-sm-none {
      display: none;
    }
    /*nav*/
    .d-sm-none {
      display: none !important;
    }
    .navwithbg {
      border-radius: 0px;
    }
    .customnotice.text-center.rounded-0.rounded-bottom {
      border-bottom-right-radius: 0rem !important;
      border-bottom-left-radius: 0rem !important;
    }
    .text-xs-center {
      text-align: center;
    }
    /*footer*/
    // .logofooter img.img-fluid {
    //   width: 60%;
    // }
    .logofooter {
      width: 100%;
      float: left;
    }
    .social {
      width: 42%;
      float: left;
    }
    .googlerev {
      width: 58%;
      float: left;
    }
    .footer-bottom p.text-light.text-small {
      color: #99b1b5 !important;
    }
    .footer-bottom p.text-light.text-small a {
      color: #99b1b5 !important;
    }
    ul.list-unstyled.footerlink li {
      margin-bottom: 25px;
      color: #33e0ff;
    }
    .mbextamargin a.h5,
    .mbextamargin .h5,
    .mbextamargin p {
      font-size: 14px;
    }

    /* Page Title */
    .page-title-image {
      height: 300px;
      object-fit: cover;
      margin-top: 0;
      min-height:300px;
    }
    .page-header-title-caption h1 {
      font-size: 32px;
    }
    /* End */
    .font-20 {
      font-size: 18px;
    }

    .page-header-title {
      position: relative;
      top: 8.6rem;
      margin: 0px 0 7rem 0;
    }
  }

  .total-cost-mobile-view {
    display: none;
  }
  @media screen and (max-width: 576px) {
   .page-header-title-caption {
      bottom: 33%;
  }
    .total-cost-mobile-view {
      display: inline-block;
    }
    .total-cost-desktop-view {
      display: none;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 990px) {
    .page-header-title-caption {
      
    }
  }
  @media screen and (min-width: 991px) and (max-width: 1390px) {
    .page-header-title-caption {
      bottom: 135px;
  }

  }
  @media screen and (max-width:768px) {
    .alert-dismissible {
      height: 57px;
      right: -2px;
    }
  }

  @media screen and (max-width: 414px) {
    .existing-medical-condition-title {
      bottom: 30px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 880px) {
    .existing-medical-condition-title {
      top: 170px;
    }
  }
}
