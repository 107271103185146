/**************************************
 * Theme Name: World 2 Cover & RACV
 * URL: https://www.world2cover.com.au
 * Tokiomarine LTD
*****************************************/
@import './bootstrap.scss';
@include bootstrap-w2c;

@mixin style-home-w2c {
  :root {
    --main-color: #00adcc;
  }

  input.form-control.input-number.noborder-0 {
    border: 0px solid #fff !important;
    text-align: center;
  }
  span.input-group-btn glyphicon {
    font-size: 25px;
  }
  button.nav-link.text-dark.ps-md-5.pe-md-5.ps-2.pe-2.active {
    border-bottom: 2px solid #fff !important;
    background: #fff !important;
  }
  .header-top {
    color: #fff;
  }
  img.mainlogo {
    width: 167px;
  }
  img.call-icon {
    width: 26px;
    margin-right: 4px;
  }
  .header-top span.number {
    color: #fff;
    font-weight: 700;
  }
  .header-top span.dayntime {
    color: #fff;
    font-weight: 400;
  }
  img.calender-icon {
    width: 22px;
    margin-right: 4px;
  }
  img.warringicon {
    width: 17px;
    margin-right: 8px;
    margin-top: -4px;
  }

  .alert.customnotice {
    background: #00adcc;
    border: none;
    color: #fff;
  }
  .navwithbg {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
    transition: ease-in-out all 0.2s;
  }
  //this is fix the sticky header for the home page
  section.header.sticky-top.solidheader {
    position: fixed;
    width: 100%;
    transition: ease-in-out all 2s;
    right: 0;
  }
  section.header.sticky-top.solidheader .navwithbg {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
    transition: ease all 0.2s;
  }
  .nav-link {
    color: #000;
  }
  section.mainhero {
    background-image: url(../../images/w2c/bg-intro.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 850px;
    margin-top: -220px;
  }
  .klook .maincobrandhero {
    background-image: url(../../images/w2c/klookbgdx.png);
    margin-top: -220px;
  }
  .klook .enterimages {
    height: 380px;
    align-content: center;
  }
  .klook .co-brand-image {
    min-width: 400px;
    float: left;
  }
  .klook .herotitle {
    display: none;
  }
  .klook-addition-word {
    color: #00adcc !important;
  }

  section.maincobrandhero {
    background-image: url(../../images/w2c/co-brand-bg-intro.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 900px;
    margin-top: -220px;
  }
  .herooverlay {
    background: linear-gradient(180deg, #000000, transparent);
    height: 250px;
  }
  .herocontent {
    height: auto;
  }
  section.mainhero h1.display-5,
  p.h4.text-light {
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }

  section.maincobrandhero {
    h1 {
      font-size: 24px;
      text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }
    h4 {
      font-size: 48px;
      text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      width: 80%;
      margin: auto auto 10px auto;
    }
  }

  .text-logo {
    margin-bottom: 30px !important;
  }

  .co-brand-image {
    max-width: 250px !important;
  }
  .co-brand-image-big {
    max-width: 150px !important;
  }
  .co-brand-image-small-to-big {
    max-width: 350px !important;
  }
  .co-brand-image-big-to-small {
    max-width: 200px !important;
  }
  .co-brand-image-canstar {
    max-width: 230px !important;
  }
  span.specialbtn {
    background: #00c5e8 !important;
  }
  .customnotice.text-center.rounded-0.rounded-bottom {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  button.btn-close.filter {
    filter: invert(1);
  }
  .sticky-top.solidheader .borderonscroll {
    border-bottom: 1px solid #ddd;
    background: #fff;
  }
  .sticky-top.solidheader .header-top span.number {
    color: #00adcc;
  }
  .sticky-top.solidheader .header-top span.dayntime {
    color: #263238;
  }
  .sticky-top.solidheader img.calender-icon {
    filter: brightness(0.5);
  }
  .sticky-top.solidheader img.call-icon {
    filter: brightness(0.5);
  }
  .sticky-top.solidheader .shadowonscroll {
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
    z-index: 99999;
  }
  .notificationss {
    z-index: -1;
    position: relative;
  }
  .main-heading {
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
    line-height: 23px;
  }
  .enterimages img {
    // max-width: 250px;
  }

  /* Hover Manu */
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link.active {
    color: #00adcc !important;
  }

  .navbar-nav .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-nav .dropdown-item:hover {
    background-color: #00adcc;
    color: #fff !important;
  }

  /*GET A QUOTE*/
  .card.quotecard {
    background: rgb(255 255 255 / 90%);
  }
  .quotecontain {
    margin-top: -450px;
  }

  .quotehome .nav-link.active {
    color: #00adcc !important;
    font-weight: 700;
  }
  button.full-width {
    width: 100%;
  }
  .quotehome.nav-tabs .nav-link.active {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .quotehome.nav-tabs .nav-link {
    border: 1px solid #00adcc !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    background: #e8e8e8;
  }
  .quotehome.nav-tabs {
    border-bottom: 1px solid #00adcc;
    padding-left: 30px;
    margin-top: 2px;
  }

  section.homequote {
    background: linear-gradient(
      to bottom,
      rgb(0 0 0 / 0%) 40%,
      rgb(237 247 249) 65%
    );
  }
  .card.quotecard {
    border-radius: 2rem !important;
  }

  .quotecard {
    box-shadow: 0px 4px 24px 0px rgb(8 133 152 / 16%);
  }
  p.smalltext {
    font-size: 12px;
    line-height: 20px;
    color: #6b959c !important;
    font-weight: 500;
  }
  small.smalltext {
    font-size: 12px;
    line-height: 20px;
    color: #6b959c !important;
    font-weight: 500;
  }
  .lightcbg {
    background: #edf7f9;
    padding: 10px;
    border-radius: 8px;
  }
  form.quoteform label {
    font-size: 14px;
    color: #808081;
  }
  input.form-control::placeholder {
    color: #b1b8bd;
    font-size: 14px;
  }
  select.form-select,
  input.form-control {
    height: 58px;
    border: 1px solid #d9d9d9 !important;
  }
  span.select2-selection.select2-selection--multiple {
    min-height: 48px;
    border: 1px solid #d9d9d9 !important;
  }
  label.btn.btn-lg {
    font-size: 18px;
  }
  p.quotereq {
    display: block;
    right: 30px;
    position: absolute;
    font-size: 12px;
    padding-top: 20px;
    color: #808080;
  }
  img.tooltipc {
    width: 20px;
    margin-top: -3px;
  }
  .lightcbg.rounded img {
    width: 20px;
    margin-top: -3px;
  }
  img.tooltipc.multitripss {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /* state fix */
  .select-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 0px;
    padding: 0px;
    width: auto;
    height: 58px;
    background-color: #fff;
  }

  .select-wrap label {
    font-size: 13px;
    color: #969899;
    padding: 6px 8px 0;
  }

  select {
    background-color: #fff;
    border: 0px;
  }

  .select-wrap select.form-select {
    height: auto;
    border: none !important;
    padding: 0px 10px !important;
  }

  .select-wrap:focus-within {
    border: 1px solid #00adcc;
  }
  .form-select {
    background-image: url(../../icons/w2c/select.png);
    background-size: 20px 20px;
  }
  /* .select2-container--default .select2-selection--multiple .select2-selection__rendered{
	margin-top:18px!important;
} */
  /*
.select2:focus ~ label,.select2:active ~ label,.select2:focus-within ~ label,select2:focus-visible ~ label {
    transform:scale(0.55) translateY(-0.5rem) translateX(0.15rem)!important;
}
*/
  select.form-select.adjustheight {
    height: 58px;
  }
  .datepicker.datepicker-dropdown {
    font-size: 14px;
    padding: 2rem 1rem;
    border: 1px solid #ddd;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    box-shadow: 0px 0px 6px 0px #ddd;
  }

  .datepicker table tr td.active.active,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled.disabled,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover,
  .datepicker table tr td.active.disabled:hover.active,
  .datepicker table tr td.active.disabled:hover.disabled,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.disabled:hover:hover,
  .datepicker table tr td.active.disabled:hover[disabled],
  .datepicker table tr td.active.disabled[disabled],
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active:hover.disabled,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active:hover:hover,
  .datepicker table tr td.active:hover[disabled],
  .datepicker table tr td.active[disabled] {
    background-color: #00adcc !important;
    background: #00adcc !important;
  }
  .datepicker table tr td.today,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today.disabled:hover,
  .datepicker table tr td.today:hover {
    background-color: #c0e6ec !important;
    background-image: linear-gradient(to bottom, #c0e6ec, #c0e6ec) !important;
  }

  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: #00adcc !important;
  }

  button.btn.btn-default.btn-number {
    height: 48px;
    font-size: 20px;
    color: #b1b8bd;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: lightblue;
  }
  .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    border-color: #ddd;
    border-radius: 0 5px 5px 0;
  }
  .select2-container--default .select2-selection--multiple {
    border-color: #ddd;
  }
  .select2-dropdown {
    z-index: 999999;
  }

  .input-date:focus {
    border: unset;
    outline: unset;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: #e1f0f3;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    margin-left: 5px;
    float: right;
    border: 1px solid #0dcaf0;
    border-radius: 35px;
    height: 20px;
    width: 20px;
    line-height: 19px;
    text-align: center;
    padding: 0;
    margin-top: 2px;
    color: #0dcaf0;
  }
  span.select2.select2-container.select2-container--default.select2-container--focus.select2-container--above.select2-container--open {
    width: 100% !important;
  }

  span.select2.select2-container.select2-container--default.select2-container--focus.select2-container--above {
    width: 100% !important;
  }
  span.select2.select2-container.select2-container--default {
    width: 100% !important;
  }
  li.select2-selection__choice {
    background-color: #e1f0f3 !important;
    border: 1px solid #0dcaf0 !important;
  }
  button.btn.btn-info.btn-lg.full-width.hp {
    height: 58px;
  }
  .qbtn {
    height: 58px !important;
  }
  /*GET A QUOTE*/

  .award {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #edf7f9;
  }
  img.aw01 {
    height: 200px;
  }

  img.aw02 {
    height: 200px;
  }

  img.aw03 {
    height: 160px;
    margin-top: 10px;
    margin-left: 35px;
    margin-right: 29px;
  }

  img.aw04 {
    height: 170px;
    margin-top: 10px;
  }
  .awardflex {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .font-14 {
    font-size: 14px;
  }
  .why img {
    width: 100px;
  }
  .why h2 a {
    color: #889ea6 !important;
  }
  .coverage {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .first {
    background: url(../../images/w2c/InternationalTrip.png);
  }
  .second {
    background: url(../../images/w2c/DomesticCover.png);
  }
  .third {
    background: url(../../images/w2c/WinterSportSki.png);
  }
  .fourth {
    background: url(../../images/w2c/ExistingMedica.png);
    background-position: bottom;
  }
  .fifth {
    background: url(../../images/w2c/covfifth.png);
    background-position: bottom;
  }
  .sixth {
    background: url(../../images/w2c/covsixth.png);
    background-position: bottom;
  }
  .first,
  .second,
  .third,
  .fourth,
  .fifth,
  .sixth {
    height: 410px;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-position: right center;
  }
  /* .first:, .second:, .third:{
    content:"";
    position: absolute;
    height:100%;
    width: 100%;
    background: linear-gradient(0deg, #0000004f, transparent);
    z-index: 1;
}

.fourth:, .fifth:, .sixth:{
    content:"";
    position: absolute;
    height:100%;
    width: 100%;
    background: linear-gradient(180deg, #0000004f, transparent);
    z-index: 1;
} */

  h2.cvty {
    z-index: 9;
  }
  p.whyw2c {
    color: #737882;
  }

  .whyone,
  .whytwo,
  .whythree,
  .whyfour {
    background-size: 100%;
  }
  .why p.lead {
    font-size: 22px;
    color: #003c46 !important;
  }

  .experience {
    background: url(../../images/w2c/GoExperience.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .covid {
    background: url(../../images/w2c/covid.png);
    background-size: cover;
  }
  img.cvbn {
    width: 44px;
    margin-bottom: 10px;
  }
  .cov-benifit {
    border: 1px solid #00adcc !important;
    border-radius: 0px 0px 30px 30px;
  }
  .cov-benifit sup {
    color: #03aecd;
  }
  .testimonials {
    background: #edf7f9;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  /*OWL CAROUSEL*/
  .owl-carousel .owl-item {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .item {
    padding: 30px 20px;
    background: #fff;
    box-shadow: 0px 2px 10px #aedde6;
    border-radius: 10px;
    margin-top: 45px;
  }
  .owl-item.active.center .item {
    transition: transform 1s;
    margin: 30px;
    transform: scale(1.2);
  }
  img.starrating {
    width: 13px !important;
    display: inline-flex;
  }
  .mbcenter p.fw-normal.mb-0 {
    font-size: 13px;
  }
  .col-5.flex {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .rattingflex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #00adcc !important;
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 15px !important;
    height: 15px !important;
    background: #97c2ca !important;
  }

  /*OWL CAROUSEL*/

  a.faqall {
    font-size: 22px;
  }
  .accordion-button.collapsed::after {
    background: url('../../images/w2c/closed.png') !important;
    width: 32px !important;
    height: 32px;
  }
  .accordion-button:not(.collapsed)::after {
    background: url('../../images/w2c/opened.png') !important;
    width: 40px !important;
    height: 40px;
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 40px !important;
    border-top-right-radius: 40px !important;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 40px !important;
    border-top-right-radius: 40px !important;
    border: 0px solid #b1b8bd;
  }
  .accordion-item:first-of-type .accordion-button.collapsed {
    border-top-left-radius: 40px !important;
    border-top-right-radius: 40px !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }
  .accordion {
    border-radius: 40px !important;
  }
  .accordion-button:not(.collapsed) {
    background-color: #fff !important;
  }
  button.accordion-button {
    font-weight: 400;
  }
  .rounded-5 {
    border-radius: 1.3rem !important;
  }
  /*newsletter*/
  .container.withbg {
    /* background: url(../../images/w2c/newsletter.png); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  section.newsletter {
    z-index: 999;
    position: relative;
    padding-bottom: 70px;
  }
  .newsletter-left {
    background: url(../../images/w2c/bg-newsletter-left.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 20px 0 0 20px;
  }
  .newsletter-right {
    border-radius: 0 20px 20px 0;
  }
  .rounded-20 {
    border-radius: 20px;
  }

  /*footer*/
  section.footer {
    background: #54575c;
    padding-top: 50px;
    z-index: 999;
  }
  ul.list-unstyled.footerlink li {
    margin-bottom: 0.75rem;
  }
  .footer-top {
    border-bottom: 1px solid #00adcc;
  }
  .text-small {
    font-size: 13px;
  }
  .footer-bottom {
    padding-bottom: 50px;
  }
  form.quoteform:focus-visible {
    outline: none !important;
  }
  :focus-visible {
    outline: none !important;
  }

  .awardflex img {
    // width: 100%;
    object-fit: contain;
    max-width: 150px;
  }

  /** modify CSS **/
  .p-multiselect-trigger .p-icon-wrapper {
    border: 1px solid #b1bbbd;
    height: 20px;
    width: 20px;
    line-height: 20px;
    padding: 4px;
    border-radius: 50%;
    font-size: 12px;
    color: #b1bbbd;
  }
  .p-multiselect-trigger .p-icon {
    width: 12px;
    height: 12px;
  }

  .exp-button-color {
    background-color: #fe5000;
    border-color: #fe5000;
  }
  /********************  Media/Responsive CSS Code 
***********************************************************/

  /*XL SCREENS*/
  @media screen and (min-width: 1400px) {
    .card-body div#nav-tabContent,
    .card-body .d-flex.justify-content-between.mobpad {
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @media screen and (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      background: #fff;
      min-width: 250px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .dropdown-item {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #00adcc;
    }
  }

  /*ADJUSTMENT*/
  @media screen and (max-width: 1400px) {
    h5.rev-title {
      font-size: 1rem !important;
    }
    p.rev-body {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
    .card-body div#nav-tabContent,
    .card-body .d-flex.justify-content-between.mobpad {
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  /* Tab Version Modify - some case */
  @media screen and (max-width: 992px) and (min-width: 768px) {
    .customnotice {
      font-size: 12px;
    }
    .covid {
      border-radius: 35px 35px 0 0;
    }
    .container.withbg {
      background: url(../../images/w2c/newsmob.png);
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;
    }
    .btnlightfull {
      background-color: #fff !important;
      color: #000;
    }
    .btnlightfull:hover {
      color: #00adcc !important;
    }
    .awardflex img {
      max-width: 140px;
      margin: 20px 0;
    }
    .awardflex > div {
      width: 100%;
    }
    .newsletter-right {
      border-radius: 20px;
      background: linear-gradient(45deg, #fff, transparent);
      box-shadow: 0px -2px 8px rgb(0 173 204 / 25%);
    }
    .card-body div#nav-tabContent,
    .card-body .d-flex.justify-content-between.mobpad {
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @media screen and (max-width: 991px) {
    .navbar-nav .dropdown-menu {
      display: block !important;
      border: none !important;
    }
    .p-calendar {
      width: 100%;
    }
  }
  @media screen and (max-width: 992px) {
    .logofooter img.img-fluid {
      max-height: 100px !important;
    }
  }

  /*ALL PHONES*/
  @media screen and (max-width: 767px) {
    .card-body div#nav-tabContent,
    .card-body .d-flex.justify-content-between.mobpad {
      padding-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    img.warringicon {
      margin-right: 0px;
    }
    .awardflex img {
      max-width: 140px;
      margin: 20px 0;
    }
    .awardflex > div {
      width: 100%;
    }
    .main-heading {
      margin-bottom: 0px;
    }
    .enterimages img {
      // max-width: 200px !important;
    }
    /*quote*/
    span.select2.select2-container.select2-container--default.select2-container--focus.select2-container--above.select2-container--open {
      width: 100% !important;
    }

    span.select2.select2-container.select2-container--default.select2-container--focus.select2-container--above {
      width: 100% !important;
    }
    span.select2.select2-container.select2-container--default {
      width: 100% !important;
    }
    .mobpad {
      padding: 30px 17px 0px 17px !important;
    }
    p.smalltext {
      font-size: 11px;
      line-height: 16px;
    }
    form.quoteform.multi-trip.mobpad p.smalltext span {
      width: 92% !important;
    }
    form.quoteform .GoingOnCruise {
      width: 41%;
    }
    form.quoteform .GoingOnSki {
      width: 41%;
    }
    .cski {
      border-radius: 7px;
    }
    .card.quotecard {
      border-radius: 2rem !important;
    }
    p.quotereq {
      top: 0px;
    }
    .quotehome.nav-tabs .nav-link {
      width: 50%;
    }
    .quotehome.nav-tabs {
      border-bottom: 0px solid #00adcc;
      padding-left: 0px;
    }
    .awardflex {
      display: inherit;
      text-align: center;
    }

    .mb-sml {
      margin-bottom: 3rem;
    }
    .p-sml {
      padding: 1rem;
    }

    .first,
    .second,
    .third,
    .fourth,
    .fifth,
    .sixth {
      height: 280px;
    }

    .row.justify-content-center.border.rounded-5.border-info.mobnoboder {
      border: 0px solid #fff !important;
      padding: 20px;
    }

    .mobwhyborder {
      border: 1px solid #00adcc !important;
      margin-bottom: 20px;
      border-radius: 20px;
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .owl-nav {
      display: none;
    }
    img.whyicon {
      width: 90px;
    }

    .experience {
      background-image: url(../../images/w2c/GoExperience.png) !important;
      background-size: cover;
      background-position: center center;
    }
    .experience {
      width: 95% !important;
      border-radius: 20px;
      text-align: center;
    }
    .covid {
      background: url(../../images/w2c/covmob.jpg) !important;
      width: 95% !important;
      border-radius: 20px 20px 0px 0px;
      background-position: top right;
      background-size: cover !important;
    }
    .cov-benifit {
      width: 95% !important;
      margin: 0px auto !important;
      border-radius: 0px 0px 20px 20px;
    }
    img.cvbn {
      width: 60px;
    }
    .cov-benifit .col-md-4 {
      margin-bottom: 25px;
    }
    .row.align-items-end.mbcenter.mb-3 {
      align-items: center !important;
    }
    .container.withbg {
      background: none;
    }
    .accordion-button,
    .accordion-button:not(.collapsed),
    .accordion-item {
      background-color: #f6f6f6 !important;
    }

    .btnlightfull {
      width: 100% !important;
      background: #fff !important;
    }
    .item {
      margin-top: 30px;
    }
    .owl-item.active.center .item {
      -webkit-transform: scale(1);
      transform: scale(1);
      margin: 20px;
    }
    .owl-item.active.center .item .rev-title,
    .owl-item.active.center .item .rev-body {
      text-align: center;
    }
    .owl-carousel .owl-item img {
      max-width: 120px;
    }
    .mbextamargin {
      margin: 30px 0px;
    }
    section.mainhero {
      height: 90vh;
    }
    section.maincobrandhero {
      h1 {
        font-size: 18px;
      }
      h4 {
        font-size: 30px;
        width: 100%;
        margin: auto auto 20px auto;
      }
    }
    .herocontent {
      height: auto;
    }
    section.homequote {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 20%,
        rgb(237, 247, 249) 40%
      );
    }
    .newsletter-right {
      border-radius: 20px;
      background: linear-gradient(45deg, #fff, transparent);
      box-shadow: 0px -2px 8px rgb(0 173 204 / 25%);
      text-align: center;
    }

    .newsletter-right label {
      text-align: left;
      width: 100%;
    }

    /* ********** */
    /*nav*/
    .d-sm-none {
      display: none !important;
    }
    .navwithbg {
      border-radius: 0px;
    }
    .customnotice.text-center.rounded-0.rounded-bottom {
      border-bottom-right-radius: 0rem !important;
      border-bottom-left-radius: 0rem !important;
    }
    .text-xs-center {
      text-align: center;
    }
    /*footer*/
    .logofooter {
      width: 100%;
      float: left;
    }
    .social {
      width: 42%;
      float: left;
    }
    .googlerev {
      width: 58%;
      float: left;
    }
    .footer-bottom p.text-light.text-small {
      color: #99b1b5 !important;
    }
    .footer-bottom p.text-light.text-small a {
      color: #99b1b5 !important;
    }
    .footer-bottom a {
      color: #04aecc !important;
    }
    ul.list-unstyled.footerlink li {
      margin-bottom: 25px;
      color: #33e0ff;
    }
    .mbextamargin a.h5,
    .mbextamargin .h5,
    .mbextamargin p {
      font-size: 14px;
    }

    .full-width-calendar-input {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 480px) {
    #offcanvasNavbar {
      width: auto;
      max-width: 400px;
    }
    h1.herotitle {
      font-size: 30px;
    }
    .h4.herosubs {
      font-size: 16px;
    }
    .quotecontain {
      margin-top: -350px;
    }
    .rtdate .ng-trigger.ng-trigger-overlayAnimation {
      /* left: -48px !important; */
    }
  }

  @media screen and (max-width: 420px) {
    .quotecontain {
      margin-top: -350px;
    }
    .h4.herosubs {
      font-size: 16px;
    }
    .rtdate .ng-trigger.ng-trigger-overlayAnimation {
      /*  left: -74px !important; */
    }
  }

  @media screen and (max-width: 400px) {
    .quotecontain {
      margin-top: -300px;
    }
    .h4.herosubs {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 380px) {
    .quotecontain {
      margin-top: -200px;
    }
    .h4.herosubs {
      font-size: 16px;
    }
  }
}
